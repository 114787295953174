let backendUri = 'https://api.blockspingaming.com/';
let spincityBackendUri = 'https://api.spincitytycoon.com/api/';
let wheelBackendUri = 'https://wheelapi.blockspingaming.com/api/';
if(window.location.hostname.includes("test.") || window.location.hostname.includes("localhost")) {
    backendUri = 'https://apitest.blockspingaming.com/';
}

if (location.protocol !== 'https:') {
    backendUri = backendUri.replace('https', 'http');
}

export default new class BackendCommunication {

    async login(loginMethod, accessToken, tokenType, code, redirectUri, referralCode, jumpTask, loginData) {

        const body = {
            loginMethod,
            accessToken,
            tokenType,
            code,
            redirectUri,
            referralCode,
            jumpTask,
            loginData
        };

        return await this.request(backendUri + 'login/', body);
    }

    async connectDiscord(loginData, accessToken, tokenType) {

        const body = {
            loginData,
            accessToken,
            tokenType
        };

        return await this.request(backendUri + 'connectdiscord/', body);
    }

    async getUser(loginData) {

        const body = {
            loginData,
        };

        return await this.request(backendUri + 'getuser/', body);
    }

    async spinWheel(loginData, spinScratch, skipRequirements) {

        const body = {
            loginData,
            spinScratch,
            skipRequirements
        };

        return await this.request(backendUri + 'spinwheel/', body);
    }

    async claimChips(loginData) {

        const body = {
            loginData,
        };

        return await this.request(backendUri + 'claimchips/', body);
    }

    async readNotification(loginData, notificationId) {

        const body = {
            loginData,
            notificationId
        };

        return await this.request(backendUri + 'readnotification/', body);
    }

    async claimNotification(loginData, notificationId) {

        const body = {
            loginData,
            notificationId
        };

        return await this.request(backendUri + 'claimnotification/', body);
    }

    async getState() {

        const body = {
        };

        return await this.request(backendUri + 'getstate/', body);
    }

    async getBets(loginData) {

        const body = {
            loginData
        };

        return await this.request(backendUri + 'getbets/', body);
    }

    async getLeaderboard(loginData) {
        const body = {
            loginData
        };

        return await this.request(backendUri + 'getleaderboard/', body);
    }

    async buyChips(chipsPackage, quantity, totalPrice, chain, walletUsername, loginData) {

        const body = {
            chipsPackage,
            quantity,
            totalPrice,
            chain,
            walletUsername,
            loginData
        };

        return await this.request(backendUri + 'buychips/', body);
    }

    async buyMonthlyCard(monthlyCardType, totalPrice, chain, walletUsername, loginData) {

        const body = {
            monthlyCardType,
            totalPrice,
            chain,
            walletUsername,
            loginData
        };

        return await this.request(backendUri + 'buymonthlycard/', body);
    }

    async buyBoost(boostType, quantity, totalPrice, buyWithGems, chain, walletUsername, loginData) {

        const body = {
            boostType,
            quantity,
            totalPrice,
            buyWithGems,
            chain,
            walletUsername,
            loginData
        };

        return await this.request(backendUri + 'buyboost/', body);
    }

    async buyJackbotPremiumUser(totalPrice, chain, walletUsername, loginData) {

        const body = {
            totalPrice,
            chain,
            walletUsername,
            loginData
        };

        return await this.request(backendUri + 'buyjackbotpremiumuser/', body);
    }

    async buyJackbotPremiumServer(quantity, totalPrice, chain, walletUsername, loginData) {

        const body = {
            quantity,
            totalPrice,
            chain,
            walletUsername,
            loginData
        };

        return await this.request(backendUri + 'buyjackbotpremiumserver/', body);
    }

    async buyTickets(ticketType, quantity, totalPrice, loginData) {

        const body = {
            ticketType,
            quantity,
            totalPrice,
            loginData
        };

        return await this.request(backendUri + 'buytickets/', body);
    }

    async enterRaffle(raffleType, quantity, loginData) {

        const body = {
            raffleType,
            quantity,
            loginData
        };

        return await this.request(backendUri + 'enterraffle/', body);
    }

    async claimNfts(chain, address, loginData) {

        const body = {
            chain,
            address,
            loginData
        };

        return await this.request(backendUri + 'claimnfts/', body);
    }

    async withdrawToken(chain, nativeToken, tokenAddress, tokenSymbol, address, loginData) {

        const body = {
            chain,
            nativeToken,
            tokenAddress,
            tokenSymbol,
            address,
            loginData
        };

        return await this.request(backendUri + 'withdrawtoken/', body);
    }

    async getPrizeCode(prizeId, loginData) {

        const body = {
            prizeId,
            loginData
        };

        return await this.request(backendUri + 'getprizecode/', body);
    }

    async unlockGame(game, price, loginData) {

        const body = {
            game,
            price,
            loginData
        };

        return await this.request(backendUri + 'unlockgame/', body);
    }

    async logout(loginData) {

        const body = {
            loginData
        };

        return await this.request(backendUri + 'logout/', body);
    }

    async init(game, loginData) {

        const body = {
            game,
            loginData,
        };

        return await this.request(backendUri + 'init/', body);
    }

    async spin(game, loginData, lines, bet) {
        const body = {
            game,
            loginData,
            lines,
            bet
        };

        return await this.request(backendUri + 'spin/', body);
    }

    async bonus(game, choice, loginData) {
        const body = {
            game,
            choice,
            loginData
        };

        return await this.request(backendUri + 'bonus/', body);
    }

    async initCrash(game, loginData) {

        const body = {
            game,
            loginData,
        };

        return await this.request(backendUri + 'initcrash/', body);
    }

    async initScratch(game, loginData) {

        const body = {
            game,
            loginData,
        };

        return await this.request(backendUri + 'initscratch/', body);
    }

    async buyScratch(game, numCards, loginData) {

        const body = {
            game,
            numCards,
            loginData,
        };

        return await this.request(backendUri + 'buyscratch/', body);
    }

    async scratchCard(game, turbo, numCards, loginData) {

        const body = {
            game,
            turbo,
            numCards,
            loginData,
        };

        return await this.request(backendUri + 'scratchcard/', body);
    }

    async initInstant(game, loginData) {

        const body = {
            game,
            loginData,
        };

        return await this.request(backendUri + 'initinstant/', body);
    }

    async joinTeam(game, team, loginData) {

        const body = {
            game,
            team,
            loginData,
        };

        return await this.request(backendUri + 'jointeam/', body);
    }

    async collect(game, loginData) {

        const body = {
            game,
            loginData,
        };

        return await this.request(backendUri + 'collect/', body);
    }

    async betInstant(game, bet, lines, difficulty, choice, loginData) {

        const body = {
            game,
            bet,
            lines,
            difficulty,
            choice,
            loginData,
        };

        return await this.request(backendUri + 'betinstant/', body);
    }

    async redeemCode(code, loginData) {

        const body = {
            code,
            loginData,
        };

        return await this.request(backendUri + 'redeemcode/', body);
    }

    async loginSpincity(loginData){
        const body = {
            loginData
        }

        return await this.request(spincityBackendUri + 'login/', body);
    }

    async getSpincityState(){
        const body = {

        }

        return await this.request(spincityBackendUri + 'getstate/', body);
    }

    async setPushNotifToken(userId, token){
        const body = {
            userId,
            token
        }

        return await this.request(wheelBackendUri + 'setpushnotiftoken/', body);
    }

    // async setPushNotifToken(userDiscordId, token){
    //     const body = {
    //         userDiscordId,
    //         token
    //     }
    //
    //     return await this.request(jackbotBackendUri + 'setpushnotiftoken/', body);
    // }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async request(uri, body) {
        let url = window.location.hostname;
        if(url.includes("test.") || url.includes("localhost")) {
            body.env = "test";
        }else{
            body.env = "prod";
        }

        let maxTries = 10;
        let tries = 0;

        while(true) {
            try {
                return await this.fetchRequest(uri, body);
            } catch(e) {
                if(tries < maxTries && e.message == "Failed to fetch") {
                    tries++;
                    await this.sleep(2000);
                } else {
                    return { error: `${e.message}, Uri: ${uri}` }
                }
            }
        }

    }

    async fetchRequest(uri, body) {

        const response = await fetch(uri, {
            method: 'post',
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(body)
        });

        return await response.json();
    }

    //It should return {rooms: [{roomBet: 1000, players: 0},{roomBet: 5000, players: 1},{roomBet: 10000, players: 1}]}
    async getSlotBattleRooms(loginData) {
        const body = {
            loginData,
        };

        return await this.request(backendUri + 'getslotbattlerooms/', body);
    }

    //It will return { slotBattleStatus: {status: "idle"/"waiting"/"battle", roomBet: 1000, game: "kingarthur"} }
    async getPlayerSlotBattleStatus(loginData) {
        const body = {
            loginData,
        };

        return await this.request(backendUri + 'getplayerslotbattlestatus/', body);
    }

    async joinSlotBattle(game, roomBet, loginData) {
        const body = {
            game,
            roomBet,
            loginData,
        };

        return await this.request(backendUri + 'joinslotbattle/', body);
    }

    async getplayerslotbattlespin(spinNumber, opponentSpin, loginData) {
        const body = {
            spinNumber,
            opponentSpin,
            loginData,
        };

        return await this.request(backendUri + 'getplayerslotbattlespin/', body);
    }

    async getplayerslotbattleresult(loginData) {
        const body = {
            loginData,
        };

        return await this.request(backendUri + 'getplayerslotbattleresult/', body);
    }


}
